:root {
    --SearchBackground: #eeeeee;
    --main-primary-branding: #005279;
    --main-secondary-color: #C92027;
    --main-primary-Color: #498128;
    --main-primary-Black: #000000;
    --main-primary-white: #ffffff;
    --main-primary-Button: #498128;
    --Button-Text-Color: #ffffff;
    --Heading-Color: var(--main-primary-branding);
    --secondary-Light-Yellow:#FFF3CD;


    /* RGB values */
    --main-primary-deep-blue-rgb: 0, 82, 121;
    --main-primary-red-color-rgb: 201, 32, 39;
    --main-primary-green-rgb: 73, 129, 40;
    --main-primary-Black-rgb: 0, 0, 0;
    --main-primary-white-rgb: 255, 255, 255;

    /* Opacity variations */
    --primary-deep-blue-opacity-10: rgba(var(--main-primary-deep-blue-rgb), 0.1);
    --primary-deep-blue-opacity-20: rgba(var(--main-primary-deep-blue-rgb), 0.2);
    --primary-deep-blue-opacity-30: rgba(var(--main-primary-deep-blue-rgb), 0.3);
    --primary-deep-blue-opacity-70: rgba(var(--main-primary-deep-blue-rgb), 0.7);
    --primary-deep-blue-opacity-80: rgba(var(--main-primary-deep-blue-rgb), 0.8);
    --primary-deep-blue-opacity-90: rgba(var(--main-primary-deep-blue-rgb), 0.9);



    --primary-red-color-opacity-10: rgba(var(--main-primary-red-color-rgb), 0.1);
    --primary-red-color-opacity-20: rgba(var(--main-primary-red-color-rgb), 0.2);
    --primary-red-color-opacity-30: rgba(var(--main-primary-red-color-rgb), 0.3);

    --primary-green-opacity-10: rgba(var(--main-primary-green-rgb), 0.1);
    --primary-green-opacity-20: rgba(var(--main-primary-green-rgb), 0.2);
    --primary-green-opacity-30: rgba(var(--main-primary-green-rgb), 0.3);

    --primary-Black-opacity-10: rgba(var(--main-primary-Black-rgb), 0.1);
    --primary-Black-opacity-20: rgba(var(--main-primary-Black-rgb), 0.2);
    --primary-Black-opacity-30: rgba(var(--main-primary-Black-rgb), 0.3);
    --primary-Black-opacity-40: rgba(var(--main-primary-Black-rgb), 0.4);
    --primary-Black-opacity-50: rgba(var(--main-primary-Black-rgb), 0.5);
    --primary-Black-opacity-60: rgba(var(--main-primary-Black-rgb), 0.6);
    --primary-Black-opacity-70: rgba(var(--main-primary-Black-rgb), 0.7);
    --primary-Black-opacity-80: rgba(var(--main-primary-Black-rgb), 0.8);
    --primary-Black-opacity-90: rgba(var(--main-primary-Black-rgb), 0.9);

    --anchor-links: var(--primary-deep-blue-opacity-90);


    // --Button-Color: var(--main-primary-Button);
    // --Transparent:var();

    --primary-Black: var(--main-primary-Black);
    --primary-deep-blue: var(--main-primary-branding);
    --primary-green: var(--main-primary-Color);
    --primary-red-color: var(--main-secondary-color);
    --neutral-white: var(--main-primary-white);

    --Pink-Lemonade: var(--primary-red-color-opacity-10);
    --secondary-medium-grey: var(--primary-Black-opacity-10);
    --secondary-Light-pink: var(--primary-red-color-opacity-10);
    --secondary-blue: var(--primary-deep-blue-opacity-90);
    --secondary-medium-blue: var(--primary-deep-blue-opacity-10);
    --secondary-light-blue: var(--primary-deep-blue-opacity-20);
    --secondary-light-green: var(--primary-green-opacity-10);
    --secondary-yellow: var(--primary-red-color-opacity-20);
    --secondary-light-grey: var(--primary-Black-opacity-10);
    --lightBlack: var(--primary-Black-opacity-70);
    --softGreyColor: var(--primary-Black-opacity-50);
    --site-background-grey: var(--primary-Black-opacity-90);

    --lightGrey: var(--primary-Black-opacity-50);
    --midnight-Black: var(--primary-Black-opacity-20);
    --primaryStartEndDate: var(--primary-deep-blue-opacity-90);
    --secondaryStartEndDate: var(--primary-deep-blue-opacity-70);
    --dateValColor: var(--primary-Black-opacity-80);
    --dateDayColor: var(--primary-Black-opacity-30);
    // --primary-Black: #000000;
    --tableHeader:rgb(204 220 228);
    --tooltipBgColor:#F1F9FE;
    --neutral-Black:#333333;
    --fontSize-28: 1.75rem;
    --fontSize-24: 1.5rem;
    --fontSize-22: 1.375rem;
    --fontSize-20: 1.25rem;
    --fontSize-18: 1.125rem;
    --fontSize-16: 1rem;
    --fontSize-14: 0.875rem;
    --fontSize-12: 0.75rem;
    --fontSize-10: 0.625rem;
 
}